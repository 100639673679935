import React, { Fragment } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

// Components
import ContentWrapper from "../components/ContentWrapper"
import TitleDescriptionImage from "../components/slices/TitleDescriptionImage"
import QuoteSlice from "../components/slices/QuoteSlice"
import TextDescriptionColumns from "../components/slices/TextDescriptionColumns"
import StackColumnsContent from "../components/slices/StackColumnsContent"
import Intro from "../components/greatfor/greatfor-template/Intro"
import CTASlice from "../components/slices/CTASlice"

// Styles
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

const GreatForTemplate = ({ data }: any) => {
  const raw = data.prismicGreatforTemplate.data
  const hero = {
    label: raw.label,
    heading: raw.heading,
    description: raw.description,
    image: raw.image,
    button: {
      label: raw.button_label,
    },
  }

  const ctaSlices = raw.body.filter(
    (slice: any) =>
      slice.slice_type === "team_member_v2" ||
      slice.slice_type === "services_cards" ||
      slice.slice_type === "resources_cards" ||
      slice.slice_type === "deep_dive_cards"
  )

  const logoData = data.allPrismicEvaluationDetail.edges[0].node.data.testimonials.map(
    (testimonial: any) => ({
      alt: testimonial.logo?.alt,
      url: testimonial.logo?.fluid,
    })
  )

  return (
    <Container>
      <ContentWrapperStyled>
        <Intro hero={hero} logos={logoData} />

        <InnerWrapper>
          {raw.body.map((slice: any, index: number) => (
            <Fragment key={`${index}-slice`}>
              {slice.slice_type === "titledescriptionimage" && (
                <TitleDescriptionImage
                  key={index}
                  primary={{
                    align: slice.primary.align,
                    description1: slice.primary.TBTDIDescription.html,
                    title: slice.primary.title.raw,
                    subtitle: slice.primary.subtitle,
                    image_position: slice.primary.image_position,
                    image: slice.primary.image,
                  }}
                />
              )}
              {slice.slice_type === "quote" && (
                <QuoteSlice
                  key={index}
                  primary={{
                    title: slice.primary.title.raw,
                    subtitle: slice.primary.subtitle,
                  }}
                />
              )}
              {slice.slice_type === "textdescriptioncolumns" && (
                <TextDescriptionColumns
                  key={index}
                  primary={{
                    columnsPerRow: slice.primary.columns_per_row,
                  }}
                  items={slice.items.map((item: any) => ({
                    title: item.title.raw,
                    description1: item.description1.html,
                  }))}
                />
              )}
              {slice.slice_type === "stackcolumnscontent" && (
                <StackColumnsContent
                  key={index}
                  items={slice.items.map((item: any) => ({
                    image: item.image,
                    title: item.title.raw,
                    description1: item.description1.html,
                  }))}
                />
              )}
            </Fragment>
          ))}
        </InnerWrapper>

        {ctaSlices.map((slice: any) => (
          <CTASlice slice={slice} />
        ))}
      </ContentWrapperStyled>
    </Container>
  )
}

export const query = graphql`
  query greatforTemplate($uid: String!, $locale: String!) {
    prismicGreatforTemplate(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        label
        heading
        description
        button_label
        image {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            srcSetWebp
            srcWebp
            sizes
          }
        }
        body {
          ... on PrismicGreatforTemplateBodyTitledescriptionimage {
            id
            slice_type
            primary {
              subtitle
              title {
                raw
              }
              align
              TBTDIDescription: description1 {
                html
              }
              image_position
              image {
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
                alt
              }
            }
          }
          ... on PrismicGreatforTemplateBodyQuote {
            id
            slice_type
            primary {
              subtitle
              title {
                raw
              }
            }
          }
          ... on PrismicGreatforTemplateBodyTextdescriptioncolumns {
            id
            slice_type
            primary {
              columns_per_row
            }
            items {
              description1 {
                html
              }
              title {
                raw
              }
            }
          }
          ... on PrismicGreatforTemplateBodyStackcolumnscontent {
            id
            slice_type
            items {
              description1 {
                html
              }
              title {
                raw
              }
              image {
                alt
                fluid {
                  aspectRatio
                  base64
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  sizes
                }
              }
            }
          }
          ... on PrismicGreatforTemplateBodyTeamMemberV2 {
            id
            slice_type
            primary {
              description1
              heading1
              team_member {
                document {
                  ... on PrismicTeamMember {
                    id
                    data {
                      name
                      role
                      photo {
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicGreatforTemplateBodyServicesCards {
            id
            slice_type
            primary {
              services_cards_button_label
              services_cards_button_link
              services_cards_heading {
                raw
              }
            }
            items {
              services_cards_template {
                document {
                  ... on PrismicServicesTemplate {
                    id
                    uid
                    data {
                      title
                      service_type
                      service_level
                      service_type_color
                      heading
                      description
                      thumbnail_image {
                        alt
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicGreatforTemplateBodyDeepDiveCards {
            id
            slice_type
            primary {
              button_label
              button_link
              deep_dive_description
              deep_dive_heading {
                raw
                text
              }
            }
            items {
              deep_dive_template {
                id
                type
                uid
                document {
                  ... on PrismicDeepDiveTemplate {
                    id
                    data {
                      heading {
                        text
                        raw
                      }
                      description
                      heading_color
                      foreground_color
                      background_color
                    }
                  }
                }
              }
            }
          }
          ... on PrismicGreatforTemplateBodyResourcesCards {
            slice_type
            primary {
              resources_cards_button_link
              resources_cards_heading {
                raw
              }
              resources_cards_label
            }
            items {
              resources_cards_template {
                document {
                  ... on PrismicResourceTemplate {
                    uid
                    data {
                      heading {
                        text
                      }
                      description {
                        text
                      }
                      image {
                        alt
                        url
                        fluid {
                          aspectRatio
                          base64
                          sizes
                          src
                          srcSet
                          srcSetWebp
                          srcWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicEvaluationDetail(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            testimonials {
              logo {
                alt
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GreatForTemplate

const Container = styled.section`
  position: relative;
  padding: 96px 0 80px;
  color: ${colors.text};
  background-color: ${colors.main["900"]};

  ${mq.from.S`
    padding: 104px 0 80px;
  `} ${mq.from.M`
    padding: 168px 0 80px;
  `};
`

const ContentWrapperStyled = styled(ContentWrapper)`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;

  ${mq.from.S`
  padding: 0 48px;
`}

  ${mq.from.L`
  padding: 0 88px;
`}
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 96px;
  max-width: 1062px;
`
