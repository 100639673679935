import * as React from "react"
import { SVGProps } from "react"

// Styling
import colors from "../../styles/colors"

// Constants
import { xmlNamespace } from "../../constants/svgUrl"

export const GreatForSmallSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={375}
      height={667}
      viewBox="0 0 375 667"
      fill="none"
      xmlns={xmlNamespace}
      {...props}
    >
      <g opacity={0.5}>
        <path
          d="M311.202 165.805C230.566 88.7112 5.45994 158.417 -68.4898 258.552C-107.501 311.377 -133.209 362.332 -86.8843 400.732C-43.5074 436.688 21.6357 428.8 95.4635 413.858C244.686 383.658 391.711 242.777 311.202 165.805Z"
          fill="url(#paint0_radial_1376_14120)"
        />
        <path
          d="M451.724 448.292C479.43 322.696 276.022 142.601 133.094 129.662C57.694 122.837 -7.90104 128 -18.0147 196.629C-27.4848 260.891 19.2755 320.36 78.1831 384.165C197.249 513.131 424.061 573.689 451.724 448.292Z"
          fill="url(#paint1_radial_1376_14120)"
        />
        <path
          d="M96.2028 275.348C-20.5884 286.844 -56.0373 460.171 11.089 555.396C46.5006 605.631 86.3008 646.737 147.39 637.213C204.591 628.296 227.377 584.226 247.081 531.306C286.906 424.343 212.809 263.87 96.2028 275.348Z"
          fill="url(#paint2_radial_1376_14120)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1376_14120"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(163.731 310.49) rotate(-150.268) scale(198.864 256.335)"
        >
          <stop stopColor={colors.purpleLight} />
          <stop
            offset={0.854733}
            stopColor={colors.purpleLight}
            stopOpacity={0}
          />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1376_14120"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(220.896 389.57) rotate(-91.5413) scale(229.267 295.525)"
        >
          <stop stopColor="#5AB9CE" />
          <stop offset={0.854733} stopColor="#54B4CB" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1376_14120"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(173.968 437.981) rotate(162.476) scale(191.19 230.19)"
        >
          <stop stopColor="#EBA26C" />
          <stop offset={0.854733} stopColor="#EB9F6A" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  )
}

export const GreatForLargeSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={945}
      height={939}
      viewBox="0 0 945 939"
      fill="none"
      xmlns={xmlNamespace}
      {...props}
    >
      <g opacity={0.5}>
        <path
          d="M646.505 233.435C532.978 124.895 216.054 223.034 111.941 364.012C57.0179 438.384 20.8236 510.122 86.044 564.186C147.114 614.808 238.828 603.702 342.769 582.666C552.858 540.148 759.852 341.802 646.505 233.435Z"
          fill="url(#paint0_radial_1376_13553)"
        />
        <path
          d="M844.342 631.145C883.35 454.32 596.974 200.766 395.747 182.55C289.593 172.94 197.242 180.21 183.003 276.832C169.671 367.305 235.504 451.03 318.439 540.862C486.07 722.431 805.397 807.69 844.342 631.145Z"
          fill="url(#paint1_radial_1376_13553)"
        />
        <path
          d="M343.811 387.659C179.382 403.844 129.474 647.869 223.98 781.935C273.836 852.66 329.87 910.533 415.876 897.125C496.409 884.57 528.49 822.524 556.23 748.019C612.3 597.427 507.979 371.5 343.811 387.659Z"
          fill="url(#paint2_radial_1376_13553)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1376_13553"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(438.882 437.136) rotate(-150.268) scale(279.978 360.891)"
        >
          <stop stopColor={colors.purpleLight} />
          <stop
            offset={0.854733}
            stopColor={colors.purpleLight}
            stopOpacity={0}
          />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1376_13553"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(519.363 548.471) rotate(-91.5413) scale(322.782 416.065)"
        >
          <stop stopColor="#5AB9CE" />
          <stop offset={0.854733} stopColor="#54B4CB" stopOpacity={0} />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1376_13553"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(453.296 616.628) rotate(162.476) scale(269.173 324.082)"
        >
          <stop stopColor="#EBA26C" />
          <stop offset={0.854733} stopColor="#EB9F6A" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  )
}
