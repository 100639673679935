import React, { FC } from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

// components
import Button from "../../../components/atoms/Button"
import { GreatForLargeSvg, GreatForSmallSvg } from "../GreatForSvgs"

// constants
import { useLocale } from "../../../hooks/useLocale"

// Styles
import colors from "../../../styles/colors"
import mq from "../../../styles/breakpoints"
import { fontWeights } from "../../../styles/textStyles"
import { fonts } from "../../../styles/fonts"

// types
import {
  IGreatForTemplateIntroProps,
  LogoProps,
} from "../../../prismic-types/templates/greatfor-template"

interface IProps {
  hero: IGreatForTemplateIntroProps
  logos: LogoProps[]
}

const Intro: FC<IProps> = ({ hero, logos }) => {
  const { getLocalizedLink } = useLocale()
  return (
    <>
      <HeroSection>
        <SmallStyledSvg />
        <LargeStyledSvg />
        <ContentSection>
          <TitleSection>
            <Label>{hero.label}</Label>
            <Title>{hero.heading}</Title>
          </TitleSection>
          <ImageWrapper>
            <GatsbyImage alt={hero.image.alt || ""} fluid={hero.image.fluid} />
          </ImageWrapper>
          <TextWrapper>
            <Description>{hero.description}</Description>
          </TextWrapper>
        </ContentSection>
        <ButtonWrapper>
          <StyledButton
            label={hero.button.label}
            href={getLocalizedLink("/contact")}
            fullWidth={false}
          />
        </ButtonWrapper>
      </HeroSection>
      {logos && (
        <Logos>
          {logos.map((logo, index) => (
            <LogoWrapper key={`logo-banner-${index}`}>
              <GatsbyImage fluid={logo.url} alt={logo.alt} />
            </LogoWrapper>
          ))}
        </Logos>
      )}
    </>
  )
}

export default Intro

const HeroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
`

const SmallStyledSvg = styled(GreatForSmallSvg)`
  position: absolute;
  z-index: 1;
  width: 100%;
  top: -20px;
  pointer-events: none;

  ${mq.from.XSM`
    display: none;
  `}
`

const LargeStyledSvg = styled(GreatForLargeSvg)`
  position: absolute;
  display: none;
  top: -150px;
  z-index: 1;
  width: 100%;
  pointer-events: none;

  ${mq.from.XSM`
    display: block;
  `}

  ${mq.from.M`
    top: -40px;
  `}
`

const ContentSection = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: column;
  gap: 48px;
  z-index: 2;
`
const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  gap: 12px;
`
const Label = styled.h4`
  font-family: ${fonts.primary};
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 2px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purpleLight};
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;

  ${mq.from.M`
    font-size: 16px;
    line-height: 16px;
  `}
`
const Title = styled.h1`
  font-size: 32px;
  line-height: 48px;
  font-weight: ${fontWeights.bold};
  color: ${colors.main["100"]};
  letter-spacing: -1.5px;
  margin-bottom: 0;
  text-align: center;

  ${mq.from.M`
    font-size: 64px;
    line-height: 76px;
  `}
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  order: 0;
  position: relative;
  z-index: 1;

  > div > div {
    padding-bottom: 50% !important;
  }

  img {
    margin-bottom: 0;
    border-radius: 16px;
    z-index: 1;
  }

  ${mq.from.S`
    width: 626px;
    height: 315px;

    img {
      width: 626px !important;
      height: 315px !important;
    }
  `}
`
const TextWrapper = styled.div`
  max-width: 1024px;
`
const Description = styled.h5`
  font-family: ${fonts.primary};
  font-size: 18px;
  line-height: 26px;
  font-weight: ${fontWeights.medium};
  color: ${colors.main["200"]};
  text-align: center;
  margin-bottom: 0;

  ${mq.from.M`
  font-size: 22px;
  line-height: 30px;
`}
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
`

const StyledButton = styled(Button)`
  margin: auto;
  font-size: 18px;
  line-height: 20px;
  font-weight: ${fontWeights.medium};
  position: relative;
  z-index: 2;
`

const Logos = styled.div`
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px 0;
  text-align: center;
  -webkit-overflow-scrolling: touch; // For smooth scrolling on iOS

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  ${mq.from.XSM`
    padding: 40px 0;
  `}
`

const LogoWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  min-width: 65px;
  margin-right: 12px;
  opacity: 0.7;

  &:last-child {
    margin-right: 0;
  }

  > div {
    width: 100%;
    height: 100%;
  }

  img {
    margin-bottom: 0;
    filter: contrast(0.5);
    transform: scale(0.9);
  }

  ${mq.from.XSM`
    margin-right: 32px;
    min-width: 152px;
  `}
`
